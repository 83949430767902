import Header from "./components/Header";
import AnimatedRoutes from "./components/AnimatedRoutes";

function App() {
  const personalDetails = {
    name: "Fernando Benítez",
    location: "Concepción del Uruguay, Entre Ríos, AR",
    email: "benitez.fernando@hotmail.com",
    availability: "Open to opportunities",
    brand:
      "Certified Tester -(CTFL)- with the skills, technical expertise, creative thinking and background that allow me to approach each project with a deep understanding of the end-user perspective, resulting in highly effective user-centric digital products.",
    certTest: "https://www.credly.com/badges/23b79b7e-18b1-4cb0-be97-580bfa9471e5/",
    certMS: "https://www.credly.com/badges/c256f542-54b8-44d6-b331-d06bd8c360be/",
  };

  return (
    <>
      <Header />
      <AnimatedRoutes personalDetails={personalDetails} />
    </>
  );
}

export default App;
